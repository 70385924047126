var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Chip, Select, Checkbox, MenuItem, TextField, InputLabel, FormControl, OutlinedInput, FormHelperText, Tooltip, } from '@mui/material';
// translation
import { useLocales } from '../../locales';
export function RHFSelect(_a) {
    var name = _a.name, label = _a.label, native = _a.native, _b = _a.maxHeight, maxHeight = _b === void 0 ? 220 : _b, helperText = _a.helperText, children = _a.children, multiple = _a.multiple, other = __rest(_a, ["name", "label", "native", "maxHeight", "helperText", "children", "multiple"]);
    var _c = useFormContext(), control = _c.control, register = _c.register;
    var translate = useLocales().translate;
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx(TextField, __assign({}, field, { select: true, fullWidth: true, label: "".concat(translate(label)), inputRef: field.ref }, register(name), { SelectProps: {
                        native: native,
                        MenuProps: {
                            PaperProps: {
                                sx: __assign({}, (!native && {
                                    px: 1,
                                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                                    '& .MuiMenuItem-root': {
                                        px: 1,
                                        borderRadius: 0.75,
                                        typography: 'body2',
                                        textTransform: 'capitalize',
                                    },
                                })),
                            },
                        },
                        sx: { textTransform: 'capitalize' },
                    }, error: !!error, helperText: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) }, other, { children: children })) })));
        } }));
}
export function RHFMultiSelect(_a) {
    var name = _a.name, chip = _a.chip, label = _a.label, options = _a.options, checkbox = _a.checkbox, editSelected = _a.editSelected, placeholder = _a.placeholder, helperText = _a.helperText, sx = _a.sx, other = __rest(_a, ["name", "chip", "label", "options", "checkbox", "editSelected", "placeholder", "helperText", "sx"]);
    var _b = useFormContext(), control = _b.control, watch = _b.watch;
    var _c = useState(editSelected || []), allselectedItems = _c[0], setSelectedItems = _c[1];
    var translate = useLocales().translate;
    var renderValues = function (value) {
        var selectedIds = value;
        var selectedItems = options === null || options === void 0 ? void 0 : options.filter(function (item) { return selectedIds.includes(item.id); });
        if (!selectedItems.length && placeholder) {
            return (_jsx(Box, __assign({ component: "em", sx: { color: 'text.disabled' } }, { children: "".concat(translate(placeholder)) })));
        }
        if (chip) {
            return (_jsx(Box, __assign({ sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }, { children: selectedItems.map(function (item) { return (_jsx(Chip, { size: "small", label: "".concat(translate(item.label), " ") }, item.id)); }) })));
        }
        return selectedItems.map(function (item) { return item.label; }).join(', ');
    };
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(FormControl, __assign({ sx: sx }, { children: [label && _jsx(InputLabel, __assign({ id: name }, { children: "".concat(translate(label)) })), _jsxs(Select, __assign({}, field, { multiple: true, displayEmpty: !!placeholder, labelId: name, input: _jsx(OutlinedInput, { fullWidth: true, label: "".concat(translate(label), " "), error: !!error }), renderValue: renderValues, native: false, MenuProps: {
                            PaperProps: {
                                sx: { px: 1, maxHeight: 280 },
                            },
                        }, value: allselectedItems }, other, { children: [placeholder && (_jsx(MenuItem, __assign({ disabled: true, value: "", sx: {
                                    py: 1,
                                    px: 2,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                } }, { children: _jsx("em", { children: "".concat(translate(placeholder)) }) }))), options.map(function (option) {
                                var selected = allselectedItems === null || allselectedItems === void 0 ? void 0 : allselectedItems.includes(option === null || option === void 0 ? void 0 : option.id);
                                return (_jsxs(MenuItem, __assign({ value: option === null || option === void 0 ? void 0 : option.id, sx: __assign(__assign({ py: 1, px: 2, borderRadius: 0.75, typography: 'body2' }, (selected && {
                                        fontWeight: 'fontWeightMedium',
                                    })), (checkbox && {
                                        p: 0.25,
                                    })), onClick: function () {
                                        return setSelectedItems(function (prev) {
                                            return prev.includes(option === null || option === void 0 ? void 0 : option.id)
                                                ? prev.filter(function (el) { return el !== (option === null || option === void 0 ? void 0 : option.id); })
                                                : __spreadArray(__spreadArray([], prev, true), [option.id], false);
                                        });
                                    } }, { children: [checkbox && _jsx(Checkbox, { disableRipple: true, size: "small", checked: selected }), option.label] }), option === null || option === void 0 ? void 0 : option.id));
                            })] })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) })))] })));
        } }));
}
